import { _Client } from "@/api-client";
import {
    CertificationRecordsFollowupActionEnum,
    CertificationRecordsHistoryDetail,
    CertificationRecordsHistoryQuery,
    CertificationRecordsHistoryRep,
    ExaminerCertificationStatusEnum,
    ModifyCertificationRecordCmd,
    SystemStatusEnum
} from "@/api-client/client";
import { defineComponent,onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { LeftOutlined} from '@ant-design/icons-vue';
import FooterBar from '@/components/FooterBar/index.vue'

export default defineComponent({
    components:{
        LeftOutlined,
        FooterBar
    },
    setup(){
        const {currentRoute,push,go} = useRouter()
        const columns= [
            {
                title: 'ID',
                key: 'ID',
                slots: { customRender: 'ID' },
                width:100
            },
            {
                title: 'Module',
                dataIndex: 'module',
                key: 'module',
            },
            {
                title:"Set",
                dataIndex:'certificationSet'
            },
            {
                title:"First Attempt Result",
                slots: { customRender: 'firstAttempt' }
            },
            {
                title:"First Attempt Result Date",
                dataIndex:'firstAttemptDate'
            },
            {
                title:"Second Attempt Result",
                slots: { customRender: 'secondAttempt' }
            },
            {
                title:"Second Attempt Result Date",
                dataIndex:'secondAttemptDate'
            },
            {
                title:"Follow-Up Action",
                width: 160,
                slots: { customRender: 'followUpAction' }
            },
            {
                title:"Certification Date",
                dataIndex:'certificationDate'
            },
            {
                title:"Action",
                align:"center",
                slots: { customRender: 'action' },
                width:200
            }
        ]
        const certificationSystemStatus = SystemStatusEnum;
        const examinerCertificationStatusEnum=ExaminerCertificationStatusEnum;
        const id = <string>currentRoute.value.params.id;
        const detailHistory:CertificationRecordsHistoryRep = ref(new CertificationRecordsHistoryRep().toJSON())

        async function getDetail() {
            detailHistory.value = await _Client.certificationRecordClient.historyQuery(new CertificationRecordsHistoryQuery({certificationId:id}))
            if (detailHistory.value.certificationSystemStatus!==SystemStatusEnum.Valid){
                let historyDetail = <CertificationRecordsHistoryDetail[]>detailHistory.value.certificationRecordsHistoryDetail;
                let item = historyDetail.find(x=>x.isCanModify)
                modify(item)
            }
        }

        const modifyCertificationRecordCmd=ref(new ModifyCertificationRecordCmd())
        const certificationRecordsFollowupActionEnum = CertificationRecordsFollowupActionEnum;
        const saveLoading= ref(false)
        function modify(item:CertificationRecordsHistoryDetail){
            modifyCertificationRecordCmd.value.init({certificationRecordId:item.certificationRecordId,followUpAction:item.followUpAction});
        }

        async function save(){
            try{
                saveLoading.value=true
                modifyCertificationRecordCmd.value.isDeleted = false;
                await _Client.certificationRecordClient.modify(modifyCertificationRecordCmd.value);
                modifyCertificationRecordCmd.value.init(new ModifyCertificationRecordCmd().toJSON());
                await getDetail();
            }finally{
                saveLoading.value=false
            }
        }

        async function deleteRecord(item:CertificationRecordsHistoryDetail){
            modifyCertificationRecordCmd.value.init({certificationRecordId:item.certificationRecordId,followUpAction:item.followUpAction,isDeleted: true});
            try{
                saveLoading.value=true
                await _Client.certificationRecordClient.modify(modifyCertificationRecordCmd.value);
                modifyCertificationRecordCmd.value.init(new ModifyCertificationRecordCmd().toJSON());
                await getDetail();
            }finally{
                saveLoading.value=false
            }
        }

        function cancel(){
            modifyCertificationRecordCmd.value.init(new ModifyCertificationRecordCmd().toJSON());
        }

        async function back(){
            await push({path:'../examiner-records'})
        }

        onMounted(async ()=>{
            await getDetail();
        })

        return {
            columns,
            certificationSystemStatus,
            examinerCertificationStatusEnum,
            detailHistory,
            back,
            modifyCertificationRecordCmd,
            modify,
            save,
            cancel,
            certificationRecordsFollowupActionEnum,
            saveLoading,
            deleteRecord 
        }
    }
})